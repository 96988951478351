define([
    "../nlgLGPDModule",
], function (nlgLGPDModule) {
    "use strict";

    return nlgLGPDModule.controller("nlgLGPDSQLController", [
        "$scope",
        "nlgLGPDSQLService",
        "lgpdSQLDefinition",
        "$state",
        "$q",
        "lgpdApiByArtifact",
        "loadingService",
        "$translate",
        "remoteExceptionHandler",
        "messagesModal",
        "nlgLGPD",
        function ($scope, sqlService, lgpdSQLDefinition, $state, $q, lgpdApiByArtifact, loadingService, $translate, remoteExceptionHandler, messagesModal, nlgLGPD) {

            $scope.mainArtifactName = nlgLGPD.getMainArtifactName();

            $scope.model = lgpdSQLDefinition;
            if (!lgpdSQLDefinition.entityName && !lgpdSQLDefinition.entityField) {
                $scope.model = null;
            }

            $scope.serviceName = "nlgLGPDSQLService";
            $scope.accessReference = "lgpd.logLgpdSQL";
            $scope.entities = getEntities();

            function getEntities() {
                return $q.all(sqlService.getEntities())
                    .then(function (allItems) {
                        var resultNames = [];
                        allItems.forEach(function (entry) {
                            entry.value.forEach(function (entity) {
                                var entityData = {
                                    entityName: entity.entityName,
                                    entityField: entity.entityField,
                                    artifactName: entry.key.artifactName,
                                };
                                resultNames.push(entityData);
                            });
                        });
                        return resultNames;
                    });
            }

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                return tag.entityName;
            };

            $scope.findScript = function (model) {
                if (!model) {
                    $scope.script.value = "";
                    return;
                }
                $scope.model = model;
                return sqlService.getScript(model.entityName, model.artifactName)
                    .then(function (result) {
                        $scope.script.value = result.data.script;
                    });
            };

            $scope.script = [{
                value: ($scope.model && $scope.model.entityName !== "") ? $scope.findScript($scope.model) : "",
            }];

            $scope.save = function () {
                sqlService.save($scope.model)
                    .then(function (result) {
                        var artifactName = $scope.model.artifactName;
                        $scope.model = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go("lgpd.editLgpdSQL", {
                                lgpdDefinitionId: $scope.model.id,
                                artifactName: artifactName
                            });
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.back = function () {
                return $state.go("lgpd.lgpdSQL", {
                    reload: true
                });
            };

            $scope.saveAndContinue = function () {
                sqlService.save($scope.model)
                    .then(function (result) {
                        $scope.model = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go($state.current, {reload: Date.now()});
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.canSave = function () {
                if (!$scope.model || $state.params.version) {
                    return false;
                }
                return $scope.model.entityName
                    && $scope.model.entityField
                    && $scope.model.personal
                    && $scope.model.justification;
            };

        }]);
});
